<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Edit Write Off Parameters">

            <div class="vx-row mb-12">

                <div class="vx-col w-full ">
                    <div class="vx-row mb-2">
                        <div class="vx-col w-1/2">
                            <vs-button type="line" icon-pack="feather" color="Red" icon="icon-arrow-left" v-on:click.stop="
                                handleBack()" />
                        </div>
                        <div class="vx-col w-1/2">

                        </div>
                    </div>

                    <div class="vx-row mb-2">
                        <div class="vx-col w-1/2">
                            <label class="vs-input--label">Customer Category</label>
                            <multiselect class="selectExample" v-model="select.distChannel" :options="option.distChannel"
                                :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                                :limit="4" :loading="loading.distChannel" placeholder=" Type to search" track-by="ID"
                                label="name">                            
                            </multiselect>
                        </div>
                        <div class="vx-col w-1/2">
                            <label class="vs-input--label">&nbsp;</label>
                            <vs-button
                                class="mr-2"
                                v-on:click="handleAddSetting()"
                                color="success"
                                type="border"
                                icon-pack="feather"
                                icon="icon-plus"
                            ></vs-button>
                        </div>
                    </div>  
                    <div class="vx-row mb-2" v-for="(tr, tr_index) in arrAgingFrom.length" v-bind:key="tr_index">
                        <div class="vx-col w-2/12">
                            <label class="vs-input--label">Aging From</label>
                            <vs-input class="w-full" type="number" v-model="arrAgingFrom[tr_index]" />
                        </div>
                        <div class="vx-col w-2/12">
                            <label class="vs-input--label">Aging To</label>
                            <vs-input class="w-full" type="number" v-model="arrAgingTo[tr_index]" />
                        </div>
                        <div class="vx-col w-3/12">
                            <label class="vs-input--label">% Provision</label>
                            <vs-input class="w-full" type="number" v-model="arrProvision[tr_index]" />
                        </div>
                        <div class="vx-col w-3/12">
                            <label class="vs-input--label">% Expense</label>
                            <vs-input class="w-full" type="number" v-model="arrExpense[tr_index]" />
                        </div>
                        <div class="vx-col w-1/12">
                            <label class="vs-input--label">&nbsp;</label>
                            <vs-button
                                class="mr-2"
                                v-on:click="handleDelSetting(arrSettingID[tr_index], tr_index)"
                                color="danger"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                            ></vs-button>
                        </div>
                    </div>  
                    <br>
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                </div>

                
          
            </div>
        
        </vx-card>
    </div>
</template>

<script>
// import DataForm from "./form";
export default {
    components: {
        // DataForm
    },
    data(){
      return {
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        mutualAction: "",
        deleteIndex: null,
        deleteId: null,
        customerId: null,
        table: {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "deleted_at",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0
        },
        selectedterritory:null,
        optionTerritory: [],
        arrSettingID: [],
        arrAgingFrom: [],
        arrAgingTo: [],
        arrExpense: [],
        arrProvision: [],
        option: {
            distChannel: [],
        },
        loading: {
            distChannel: false,
        },
        counterLoading: {
            distChannel: 0,
        },
        select: {
            distChannel: null,
        },
      };
    },
    mounted() {
        this.getOptionDistChannel();
        this.getData(this.$route.params.id);
    },
    
    methods: {
        handleBack() {
            this.$router.push({name: 'write-off-parameters'});            
        },
        handleAddSetting(){
            this.arrAgingFrom.push('')
            this.arrAgingTo.push('')
            this.arrProvision.push('')
            this.arrExpense.push('')
            this.arrSettingID.push('0')
        },
        handleDelSetting(id, index){
            this.deleteId = id;
            this.deleteIndex = index;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptDelete
            });            
        },
        acceptDelete() {
            this.$vs.loading();
            console.log("this.deleteId", this.deleteId)
            console.log("this.index", this.deleteIndex)
            this.$http.delete("/api/v1/setting/write-off-parameters/delete/"+ this.deleteId).then(resp => {
                this.$vs.loading.close();
                console.log(resp);
                    if (resp.code == 200) {
                        this.arrAgingFrom = this.arrAgingFrom.filter((v, j) => { return j != this.deleteIndex; })
                        this.arrAgingTo = this.arrAgingTo.filter((v, j) => { return j != this.deleteIndex; })
                        this.arrProvision = this.arrProvision.filter((v, j) => { return j != this.deleteIndex; })
                        this.arrExpense = this.arrExpense.filter((v, j) => { return j != this.deleteIndex; })
                        this.arrSettingID = this.arrSettingID.filter((v, j) => { return j != this.deleteIndex; })

                        this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "The data was successfully deleted",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                        });
                    } else {
                        this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                this.$vs.loading.close();
                console.log(error);
                });
            },
        handleSubmit() {
            let customer_category_id = this.$route.params.id;
            let total = 100;

            let lines = {
                aging_from      : this.arrAgingFrom,
                aging_to        : this.arrAgingTo,
                provision       : this.arrProvision,
                expense         : this.arrExpense,
                setting_ids     : this.arrSettingID,
            }

            if (this.select.distChannel == null ) {
                this.$vs.notify({
                    color: "danger",
                    title: "Warning",
                    text: "Customer category is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            if (this.arrAgingFrom.length == 0 || this.arrAgingTo.length == 0 || this.arrProvision.length == 0 || this.arrExpense.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Warning",
                    text: "Please add at least 1 setting",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }else{
                let isTrueAgingFT = true, isTruePE = true;
                let isAgingNotEmpty = true, isPE_not_empty = true;

                for (let i = 0; i < this.arrAgingFrom.length; i++) {
                    let aging_from = this.arrAgingFrom[i];
                    let aging_to = this.arrAgingTo[i];
                    let provision = this.arrProvision[i];
                    let expense = this.arrExpense[i];

                    // Check is true Aging From <= aging To
                    if (parseInt(aging_from) >= parseInt(aging_to)) {
                        isTrueAgingFT = false;
                    }

                    // Check if aging from or aging to != '' (empty string)
                    if (aging_from === '' || aging_to === '') {
                        isAgingNotEmpty = false;
                    }

                    // Check is provision + expense == 100 ?
                    if (parseInt(provision) + parseInt(expense) != total) {
                        isTruePE = false;
                    }

                    // Check if prov or exp == '' (empty string)
                    if (provision === '' || expense === '') {
                        isPE_not_empty = false;
                    }
                                        
                }

                // Check is true Aging From <= aging To
                if (!isTrueAgingFT) {
                    this.$vs.notify({
                        color: "danger",
                        title: "error",
                        text: "Aging From must be lower than Aging To",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isAgingNotEmpty) {
                    this.$vs.notify({
                        color: "danger",
                        title: "error",
                        text: "Aging From & Aging To is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isTruePE) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Warning",
                        text: "Provision + Expense must be 100",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isPE_not_empty) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Warning",
                        text: "Provision & Expense is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }
            }

            // console.log("handleSubmit");
            let param = {
                customer_category_id : parseInt(customer_category_id),
                setting_lines : lines,                
            }
            // console.log("______param________");
            // console.log(param);
            // console.log(this.select.distChannel);

            this.$http.put("/api/v1/setting/write-off-parameters/update", param).then((resp) => {
                // console.log(resp);
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Success Update Data",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Gagal!",
                            text: "Failed Update Data",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    
                console.log(resp);
                this.$nextTick(() => {
                    this.$emit("closeDetail", true);
                    this.$vs.loading.close();
                    this.handleBack()
                });                
            }).catch((e) => {
                console.log(e);
                this.$vs.notify({
                    color: "danger",
                    title: "error catch",
                    text: "error catch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.handleBack()
            });
        },
        getData(id) {
            this.$vs.loading();
            this.$http
            .get("/api/v1/setting/write-off-parameters/distchannel/"+id)
            .then(resp => {
                // console.log("respon");
                // console.log(resp);
                if (resp.status == "success") {                    
                    resp.data.forEach(dt => {
                        this.arrSettingID.push(dt.ID.toString());
                        this.arrAgingFrom.push(dt.From.toString());
                        this.arrAgingTo.push(dt.To.toString());
                        this.arrProvision.push(dt.Provision.toString());
                        this.arrExpense.push(dt.Expense.toString());
                    });
                this.$vs.loading.close();
                } else {
                    console.log(resp.message);
                }
            });
        },
        getOptionDistChannel(query) {            
            let param = {
                search: query,
            };
            console.log(param)

            this.loading.distChannel = true
            this.counterLoading.distChannel++
            this.$http.get('/api/v1/write-off/distribution-channel', {
                params: param,
            }).then((r) => {
                // console.log(r);
                let distChannels = r.data.distributionChannel

                this.counterLoading.distChannel--
                if (this.counterLoading.distChannel == 0) {
                    this.loading.distChannel = false
                }
                
                distChannels.forEach(dc => {
                    if (dc.ID == this.$route.params.id) {
                        // this.selected.distChannel = resp.data[0].name;
                        this.select.distChannel = dc;
                        this.option.distChannel = [];
                        this.option.distChannel.push(dc);
                    }
                });


            }).catch((e) => {
                console.log(e)
            })
        },        
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            }
        }
    }
}
</script>